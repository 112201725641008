import request from './request'

const api = {
	// 获取商品列表
	goodlist: '/mall/Product/list',
	// 获取列表
	lists: '/live/LiveGoods/List',
	// 添加
	add: '/live/LiveGoods/create',
	// 删除
	dele: '/live/LiveGoods/delete',
	// 编辑
	edit: '/live/LiveGoods/update',
	// 详情
	info: '/live/LiveGoods/read',
}

export {
	request,
	api
}
